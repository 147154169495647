import { render, staticRenderFns } from "./newpromotion.vue?vue&type=template&id=714b18da&scoped=true"
import script from "./newpromotion.vue?vue&type=script&lang=js"
export * from "./newpromotion.vue?vue&type=script&lang=js"
import style0 from "./newpromotion.vue?vue&type=style&index=0&id=714b18da&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "714b18da",
  null
  
)

export default component.exports